<template>
  <div>
    <v-form @submit.prevent="submitResponse">
      <p class="mb-5">
        Determine whether each of the following compounds is <b>ionic</b> or <b>molecular</b>:
      </p>
      <v-simple-table style="max-width: 400px" class="pl-8">
        <thead>
          <tr>
            <th style="text-align: left; font-size: 15px">System</th>
            <th style="text-align: left; font-size: 15px">Compound Type</th>
          </tr>
        </thead>
        <tr>
          <td />
          <td>
            <stemble-latex :content="'$\\ce{ZnCl2}$'" />
          </td>
          <td>
            <v-radio-group v-model="inputs.input1">
              <v-radio
                v-for="option in options"
                :key="option.value"
                class="d-inline-block"
                :value="option.value"
              >
                <template #label>
                  <stemble-latex :content="option.text" />
                </template>
              </v-radio>
            </v-radio-group>
          </td>
        </tr>

        <tr>
          <td>
            <stemble-latex :content="'$\\ce{SO2}$'" />
          </td>
          <td>
            <v-radio-group v-model="inputs.input2">
              <v-radio
                v-for="option in options"
                :key="option.value"
                class="d-inline-block"
                :value="option.value"
              >
                <template #label>
                  <stemble-latex :content="option.text" />
                </template>
              </v-radio>
            </v-radio-group>
          </td>
        </tr>

        <tr>
          <td>
            <stemble-latex :content="'$\\ce{CH4}$'" />
          </td>
          <td>
            <v-radio-group v-model="inputs.input3">
              <v-radio
                v-for="option in options"
                :key="option.value"
                class="d-inline-block"
                :value="option.value"
              >
                <template #label>
                  <stemble-latex :content="option.text" />
                </template>
              </v-radio>
            </v-radio-group>
          </td>
        </tr>

        <tr>
          <td>
            <stemble-latex :content="'$\\ce{NaBr}$'" />
          </td>
          <td>
            <v-radio-group v-model="inputs.input4">
              <v-radio
                v-for="option in options"
                :key="option.value"
                class="d-inline-block"
                :value="option.value"
              >
                <template #label>
                  <stemble-latex :content="option.text" />
                </template>
              </v-radio>
            </v-radio-group>
          </td>
        </tr>
      </v-simple-table>
    </v-form>
  </div>
</template>

<script>
import DynamicQuestionMixin from '../../mixins/dynamic-question';
import StembleLatex from '@/tasks/components/StembleLatex';

export default {
  name: 'Question56',
  components: {StembleLatex},
  mixins: [DynamicQuestionMixin()],
  data() {
    return {
      // Put a list of inputs or computed properties here to be sent for marking

      inputs: {
        input1: null,
        input2: null,
        input3: null,
        input4: null,
      },
      options: [
        {text: '$\\text{Ionic}$', value: 'ionic'},
        {text: '$\\text{Molecular}$', value: 'molecular'},
      ],
    };
  },
};
</script>
